import React from 'react'
import { graphql, navigate } from "gatsby"
import Page from "components/page"
import YoutubeVideo from "components/youtubeVideo"
import { ShareButton } from "components/shareButton"
import DefaultLink from "components/defaultLink"
import SEO from "components/seo"
import { useMobile } from "utils/useMobile"

export const ProfileVideoPage = ({ pageContext: profile, data, ...props }) => {
    const { share } = data.siteData.childContentYaml.modals
    const { result } = data.allContentJson.edges[0].node
    const image = data.profileWithImage.childMarkdownRemark.frontmatter.headband.image?.childImageSharp?.fluid?.src
    const isMobile = useMobile()

    const setVh = () => {
        const vh = window.innerHeight * 0.01
        document && document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    React.useEffect(() => {
        document && document.querySelector('body').classList.add("no-padding")
        setVh()
        return () => {
            document && document.querySelector('body').classList.remove("no-padding")
        }
    }, [])

    if (typeof window !== "undefined")
        window.addEventListener('resize', setVh)

    let videoUrl = isMobile ? profile.video.portrait : profile.video.landscape

    return (
        <Page>
            <SEO page={{ ...profile.seo, image }} />

            <main className="c-video-view">
                <YoutubeVideo
                    className="c-video-view__video"
                    url={videoUrl}
                    autoplay={true}
                    playerProps={{
                        playing: true,
                        width: "100%",
                        height: "100%",
                        controls: false,
                        onEnded: () => navigate(`/profils/${profile.slug}`),
                        config: {
                            youtube: {
                                playerVars: {
                                    color: "#EA6548",
                                    autoplay: 1,
                                    controls: 0,
                                    fs: 0,
                                    modestbranding: 1,
                                    onUnstarted: () => navigate(`/profils/${profile.slug}`),
                                },
                            }
                        }
                    }}
                />

                <section className="c-video-view__controls u-pd-l@main">
                    <DefaultLink link={`/profils/${profile.slug}`} className={"c-btn c-btn--black u-flex-align-self-end u-pd-hz-m u-mg-bottom-m@main"}>
                        <span className="u-font-adjustment">{result.cta.skip}</span>
                        <span className={'icon-FLECHE_CTA u-secondary u-mg-left-m'}></span>
                    </DefaultLink>

                    <ShareButton
                        className="c-btn c-btn--black u-flex-align-self-end u-pd-hz-m u-mg-bottom-m@main"
                        shareUrl={videoUrl}
                        shareTitle={result.cta.shareVideo}
                        shareOptions={share.video}
                    >
                        <span className="u-font-adjustment">{result.cta.shareVideo}</span>
                        <span className={'icon-PARTAGE u-secondary u-mg-left-m'}></span>
                    </ShareButton>

                    <ShareButton
                        className="c-btn c-btn--black u-flex-align-self-end u-pd-hz-m u-mg-bottom-m@main"
                        shareTitle={result.cta.shareQuiz}
                    >
                        <span className="u-font-adjustment">{result.cta.shareQuiz}</span>
                        <span className={'icon-PARTAGE u-secondary u-mg-left-m'}></span>
                    </ShareButton>
                </section>
            </main>
        </Page>
    )
}

export const query = graphql`
    query QueryProfileVideo($id: String!) {
        allContentJson {
            edges {
                node {
                    result {
                        title
                        cta {
                            skip
                            shareVideo
                            shareQuiz
                        }
                    }
                }
            }
        }
        siteData: file(name: {eq: "site"}, sourceInstanceName: {eq: "site"}) {
            childContentYaml {
                modals {
                    share {
                        video {
                            default_message
                            facebook_message
                            twitter_message
                            linkedin_message
                            email_subject
                            email_message
                        }
                    }
                }
            }
        }
        profileWithImage: file(id: {eq: $id}) {
            childMarkdownRemark {
                frontmatter {
                    headband {
                        image {
                            id
                            childImageSharp {
                                fluid(maxWidth: 400) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default ProfileVideoPage